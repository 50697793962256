import { useNavigate } from 'react-router-dom';
import { useEventStore } from '../../store/eventStore';
import { Api } from '../autogenerated/Api';
import { Auth } from '../baseApiConfig';

const getEvents = () => {
  const axiosConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json', 
        Authorization: `Bearer ${Auth.getAccessToken()}`, 
      },
  };
  const meltpotApiTest = new Api({...axiosConfig})
  meltpotApiTest.api.retrieveAllEventsUsingGet()
    .then((response) => {
        if(response.data.events){
          useEventStore.getState().setEvents(response.data.events);
        }
       
      })
      .catch((error) => {
        console.error('Error making API call:', error);
        if (error.response && error.response.status === 403) {
          Auth.removeAccessToken();
         
        }
      });
};
export default getEvents;