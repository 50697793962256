import { create } from "zustand";
import { AvailableEventOutputDto, EventOutputDto, FeedbackOutputDto, HistoryEventOutputDto } from "../network/autogenerated/Api";
import { devtools } from "zustand/middleware";

interface EventStoreState {
    events: EventOutputDto[];
    feedback: FeedbackOutputDto[];

}
interface EventStoreActions {
    setEvents: (events: EventOutputDto[]) => void;
    createEvent: (event: EventOutputDto) => void;
    updateEvent:(event : EventOutputDto) => void;
    setFeedback: (feedback: FeedbackOutputDto[]) => void;
}
export const useEventStore = create<EventStoreState & EventStoreActions>()(
devtools(
    (set) => ({
    events: [],
    feedback: [],
    setEvents: (e: EventOutputDto[]) => set(() => ({  events: [...e] }),false, 'setEvents'),
    createEvent: (event: EventOutputDto) => set((state) => ({ events: [...state.events, event] }),false, 'createEvent'), 
    updateEvent: (updatedEvent: EventOutputDto) => set((state) => ({
        events: state.events.map(event =>
            event.id === updatedEvent.id ? updatedEvent : event
        )
    }), false, 'updateEvent'),
    setFeedback: (f: FeedbackOutputDto[]) => set({ feedback : [...f] },false, 'setFeedback'),
}),{
    enabled : true,
    trace :true,
    serialize: true, // Whether to serialize state changes
    logArgs: false, // Whether to log arguments
    name:"Meltpot Events DEV"
  }
  ));