import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import postCountry from '../../services/network/CountryMngt/PostCountry';
import {  IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import postRegion from '../../services/network/RegionsMngt/PostRegion';
import { useBusinessStore } from '../../services/store/businessStore';
import { RegionInputDto, RegionOutputDto } from '../../services/network/autogenerated/Api';
import patchRegion from '../../services/network/RegionsMngt/PatchRegion';

interface AddEditRegionModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRegion? : RegionOutputDto | null
}

const AddEditRegionModal: React.FC<AddEditRegionModalProps>  = ({ isOpen, onClose, selectedRegion }) => {
  const [formData, setFormData] = useState<RegionInputDto>({});
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const {selectedCountry} = useBusinessStore();  


  useEffect(() => {
    if (selectedRegion) {
      setFormData({
        name: selectedRegion.name || '',
        imageUrl: selectedRegion.imageUrl || '',
        countryId: selectedCountry?.id
      });
    } else {
        setFormData({ countryId: selectedCountry?.id, name: '', imageUrl: '' });
    }
  }, [selectedRegion, selectedCountry]);
   const handlePostRegion = () => {
    const data = { name: formData.name,imageUrl : formData.imageUrl, countryId: selectedCountry?.id }; 
    postRegion(data)
        .then(() => {
            onClose(); // Close modal upon successful post
        })
        .catch((error) => {
            console.error('Error posting region:', error);
           
        });
   }

   const handlePatchRegion = () => {
    const data = { name: formData.name,imageUrl : formData.imageUrl, countryId: selectedCountry?.id }; 
    if(selectedRegion?.id){
        patchRegion(selectedRegion.id, data)
        .then(() => {
            onClose(); // Close modal upon successful post
        })
        .catch((error) => {
            console.error('Error posting region:', error);
           
        });
    }
   
   }

   const isActionButtonDisabled = !(
    formData.name &&
    formData.imageUrl
  );




  return (
    <>
    <Modal open={isOpen} onClose={onClose}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
               <div style={{ display: 'flex', flexDirection: 'column' }}>
                {!selectedRegion ? 
               <h2 style={{ display: 'flex', alignSelf:'center' }}>Create Region</h2> : <h2 style={{ display: 'flex', alignSelf:'center' }}>Update Region</h2>
               }
               
               <TextField
                   label="Region Name"
                   value={formData.name}
                   onChange={(e) => setFormData({...formData, name: e.target.value})}
                   fullWidth
                   margin="normal"
               />
                 <TextField
                   label="Img Url"
                   value={formData.imageUrl}
                   onChange={(e) => setFormData({...formData, imageUrl: e.target.value})}
                   fullWidth
                   margin="normal"
               />
               <TextField
                   label="Associated Country"
                   value={selectedCountry?.name}
                    disabled
                   fullWidth
                   margin="normal"
               />
            
             {!selectedRegion? 
               <div style={{ alignSelf: 'center' }}>
                   <Button onClick={handlePostRegion} variant="contained" color="primary" disabled={isActionButtonDisabled} >Create Region</Button>
               </div>
               :
               <div style={{ alignSelf: 'center' }}>
               <Button onClick={handlePatchRegion} variant="contained" color="primary" disabled={isActionButtonDisabled} >Update Region</Button>
           </div>
            }
              
           </div>
            
        </div>
    </Modal>
</>

  );
};

export default AddEditRegionModal;
