import React, { useEffect, useState } from 'react';
import { Modal, TextField, Button, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import postEvent from '../../services/network/EventMngtService/PostEvent';
import { EventInputDto, EventOutputDto } from '../../services/network/autogenerated/Api';
import { useBusinessStore } from '../../services/store/businessStore';
import { format } from 'date-fns';
import updateEvent from '../../services/network/EventMngtService/UpdateEvent';

interface EventModalProps {
  isOpen: boolean;
  selectedEvent? : EventOutputDto;
  onClose: () => void;
}
const EventModal: React.FC<EventModalProps>  = ({ isOpen, onClose, selectedEvent }) => {
  const {regions} = useBusinessStore();

  const [formData, setFormData] = useState<EventInputDto>({
    announcementDateTime: '',
    capacity: 0,
    dateTime: '',
    regionId: '',
    reservationPrice: 0,
  });
//TODO Falta transformar de UTC para Unidades do MUI
  useEffect(() => {
    if (selectedEvent) {
      setFormData({
        announcementDateTime: selectedEvent.announcementDateTime,
        capacity: selectedEvent.capacity,
        dateTime: selectedEvent.dateTime,
        regionId: selectedEvent.region?.id || '',
        reservationPrice: selectedEvent.reservationPrice,
      });
    }
  }, [selectedEvent]);


  const handleChange = (e : any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreate = () => {
    if(formData.announcementDateTime !== undefined && formData.dateTime !== undefined){
      const data = {...formData, announcementDateTime: format(formData.announcementDateTime, "yyyy-MM-dd HH:mm:ss.SSS'Z'"), dateTime :format(formData.dateTime, "yyyy-MM-dd HH:mm:ss.SSS'Z'")}
    postEvent(data)
     .then(() => {
      onClose(); 
   })
  .catch((error) => {
      console.error('Error creating Event:', error);
      
  });
}
  };

  
  const handleUpdate = () => {
    debugger;
    if(formData.announcementDateTime !== undefined && formData.dateTime !== undefined){
      const data = {...formData, announcementDateTime: format(formData.announcementDateTime, "yyyy-MM-dd HH:mm:ss.SSS'Z'"), dateTime :format(formData.dateTime, "yyyy-MM-dd HH:mm:ss.SSS'Z'")}
    updateEvent(selectedEvent?.id || '0', data)
     .then(() => {
      onClose(); 
   })
  .catch((error) => {
      console.error('Error Updating Event', error);
      
  });
}
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="reservation-modal-title"
      aria-describedby="reservation-modal-description"
    >
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
        {!selectedEvent ? <h2 id="reservation-modal-title"> Create Event</h2> :<h2 id="reservation-modal-title"> Edit Event</h2> }
        <FormControl fullWidth margin="normal">
          <TextField
            label="Announcement Date Time"
            name="announcementDateTime"
            type="datetime-local"
            value={formData.announcementDateTime}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Capacity"
            name="capacity"
            type="number"
            value={formData.capacity}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Event Date Time"
            name="dateTime"
            type="datetime-local"
            value={formData.dateTime}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="region-select-label">Region</InputLabel>
          <Select
            labelId="region-select-label"
            id="region-select"
            name="regionId"
            value={formData.regionId}
            onChange={handleChange}
          >
            {regions.map((region) => (
              <MenuItem key={region.id} value={region.id}>
                {region.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Reservation Price"
            name="reservationPrice"
            type="number"
            value={formData.reservationPrice}
            onChange={handleChange}
          />
        </FormControl>
        {!selectedEvent ? <Button onClick={handleCreate} variant="contained" color="primary">Create Event</Button> :<Button onClick={handleUpdate} variant="contained" color="primary">Update Event</Button>}
      </div>
    </Modal>
  );
};

export default EventModal;
