import { ReactElement, useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useBusinessStore } from '../../services/store/businessStore';
import { Fab, Typography } from '@mui/material';
import { RegionOutputDto } from '../../services/network/autogenerated/Api';
import AddEditRegionModal from '../../Components/Modal/AddEditRegionModal';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';



export const RegionMngt: React.FC = (): ReactElement => {
    const { regions, selectedCountry }= useBusinessStore();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // State to manage the modal open/close
    const [filteredRegions, setFilteredRegions] = useState<RegionOutputDto[]>([]);
    const [regionToEdit, setRegionToEdit] = useState<RegionOutputDto | null>(null)

    useEffect(() => {
      const filtered = regions.filter(r => r.country?.id === selectedCountry?.id);
      setFilteredRegions(filtered);
    }, [regions, selectedCountry]);
  

      const openModal = () => {
        setRegionToEdit(null)
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleEditRegion = (region : RegionOutputDto)=>{
       setRegionToEdit(region)
        setIsModalOpen(true)
    }
    return (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
            <Typography variant="h5" gutterBottom>
              List of Regions
            </Typography>
            {filteredRegions.map((region, index) => (
              <Paper
                key={index}
                style={{
                  display:'flex',
                  justifyContent:'space-evenly',
                  alignItems:'center',
                  margin: '8px',
                  padding: '16px',
                  width: '50%',
                  textAlign: 'center',
                  backgroundColor: '#2196f3', // blue color
                  color: '#ffffff', // white color
                  borderRadius: '8px',
                }}
                elevation={3}
              >
                {region.name}

                <Button
                sx={{display:'flex'}}
            variant="contained"
            color="secondary"
            style={{ marginTop: '16px' }}
            onClick={() => handleEditRegion(region)}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
              </Paper>
            ))}
          </div>
          <Fab
                color="primary"
                aria-label="add"
                onClick={openModal}
                sx={{
                    position: 'fixed',
                    bottom: '5vh',
                    right: '5vw',
                    zIndex: 9999, // Ensure it's above other elements
                }}
            >
                <AddIcon />
            </Fab>
            <AddEditRegionModal isOpen={isModalOpen} onClose={closeModal} selectedRegion={regionToEdit} />
        </>
      );
    };