import { create } from "zustand";
import { devtools } from "zustand/middleware";


interface MediaStoreState {
  mediaFiles: string[];
}

interface MediaStoreActions {
  setMediaFiles: (files: string[]) => void;
  addMediaFile: (file: string) => void;
}

export const useMediaStore = create<MediaStoreState & MediaStoreActions>()(
  devtools(
    (set) => ({
      mediaFiles: [],
      setMediaFiles: (files: string[]) =>
        set(() => ({ mediaFiles: [...files] }), false, 'setMediaFiles'),
      addMediaFile: (file: string) =>
        set((state) => ({ mediaFiles: [...state.mediaFiles, file] }), false, 'addMediaFile'),
    }),
    {
      enabled: true,
      trace: true,
      name: 'Meltpot Media DEV',
    }
  )
);
