import { useBusinessStore } from "../../store/businessStore";
import { Api } from "../autogenerated/Api";
import { Auth } from "../baseApiConfig";

const getAllBusiness = () => {
  const axiosConfig = {
    baseURL: process.env.REACT_APP_API_URL, 
    headers: {
        'Content-Type': 'application/json', 
        Authorization: `Bearer ${Auth.getAccessToken()}`, 
      },
  };
  const meltpotApi = new Api({...axiosConfig})
    meltpotApi.api.retrieveBusinessesUsingGet()
    .then((response) => {
        if(response.data){
          useBusinessStore.getState().setBusiness(response.data);
        }
    
      })
      .catch((error) => {
        console.error('Error making API call:', error);
        if (error.response && error.response.status === 403) {
          Auth.removeAccessToken();
      }});
};


export default getAllBusiness;