import React, { ReactElement, useEffect, useState } from 'react';
import { useEventStore } from '../../services/store/eventStore';
import { Button, Card, CardHeader, Collapse, Container, Fab, IconButton, List, ListItem, ListItemText, Typography, Box, Grid } from '@mui/material';
import { BusinessOutputDto, ConsumerUserOutputDto, EventGroupOutputDto, EventOutputDto } from '../../services/network/autogenerated/Api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import { useBusinessStore } from '../../services/store/businessStore';
import AddParticipantsToGroupModal from '../../Components/Modal/AddParticipantsToGroupModal';
import { useParams } from 'react-router-dom';

export const EventDetails: React.FC = (): ReactElement => {
    const { eventId } = useParams();
    const { events } = useEventStore();
    const { business } = useBusinessStore();
    const [availableParticipants, setAvailableParticipants] = useState<ConsumerUserOutputDto[]>([]);
    const [event, setEvent] = useState<EventOutputDto | undefined>();
    const [availableBusiness, setAvailableBusiness] = useState<BusinessOutputDto[]>();
    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const updateAvailableParticipants = (currentEvent: EventOutputDto) => {
        const participantsInGroups = currentEvent.groups?.flatMap(group => group.participants) || [];
        const participantsUniverse = currentEvent.participants || [];

        const updatedAvailableParticipants = participantsUniverse.filter(
            participant => !participantsInGroups.some(
                groupParticipant => groupParticipant?.id === participant.id
            )
        );
        setAvailableParticipants(updatedAvailableParticipants);
    };

    const toggleGroup = (groupId?: string) => {
        if (groupId) {
            setExpandedGroups((prevState) =>
                prevState.includes(groupId)
                    ? prevState.filter((id) => id !== groupId)
                    : [...prevState, groupId]
            );
        }
    };

    useEffect(() => {
        const foundEvent = events.find(event => event.id === eventId);
        if (foundEvent) {
            const foundBusiness = business.filter(b => b.region?.id === foundEvent.region?.id)
            setEvent(foundEvent);
            setAvailableBusiness(foundBusiness)
            updateAvailableParticipants(foundEvent);
        }
    }, [events, eventId]);

    return (
        <Container sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>Event Details</Typography>
            <Box sx={{ mb: 2 }}>
                <Typography variant="body1"><strong>Event ID:</strong> {event?.id}</Typography>
                <Typography variant="body1"><strong>Event Date:</strong> {event?.dateTime}</Typography>
                <Typography variant="body1"><strong>Capacity:</strong> {event?.capacity}</Typography>
            </Box>

            <Typography variant="h5" gutterBottom>Groups</Typography>
            <Grid container spacing={2}>
                {event?.groups?.map((group) => (
                    <Grid item xs={12} sm={6} md={4} key={group?.id}>
                        <Card>
                            <CardHeader
                                title={group.business?.name}
                                subheader={group?.groupNumber}
                                action={
                                    <IconButton onClick={() => toggleGroup(group?.id)}>
                                        { group?.id && expandedGroups.includes(group?.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                }
                                sx={{ backgroundColor: '#f7f7f7' }}
                            />
                            <Collapse in={!!group?.id && expandedGroups.includes(group?.id)}> 
                                <List>
                                    {group.participants?.map((participant) => (
                                        <ListItem key={participant.id}>
                                            <ListItemText primary={participant.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            
            <Fab
                color="primary"
                aria-label="add"
                onClick={handleOpenModal}
                sx={{
                    position: 'fixed',
                    bottom: '5vh',
                    right: '5vw',
                    zIndex: 9999, // Ensure it's above other elements
                }}
            >
                <AddIcon />
            </Fab>
            {/**TODO lacar erro quando nao existem available business */}    
            { eventId && availableBusiness && <AddParticipantsToGroupModal
                open={isModalOpen}
                onClose={handleCloseModal}
                eventId={eventId}
                availableParticipants={availableParticipants}
                business={availableBusiness}
            />}
        </Container>
    );
};
