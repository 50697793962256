import { useBusinessStore } from '../../store/businessStore';
import { Api, RegionInputDto, RegionOutputDto } from '../autogenerated/Api';
import { Auth } from '../baseApiConfig';

const postRegion = (data: RegionInputDto): Promise<RegionOutputDto> => {
    return new Promise((resolve, reject) => {
      const axiosConfig = {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getAccessToken()}`,
        },
      };
  
      const endpoint = new Api({ ...axiosConfig });
      endpoint.api
        .addRegionUsingPost(data)
        .then((response) => {
          if (response.data) {
            useBusinessStore.getState().createRegion(response.data);
            resolve(response.data); // Resolve the promise with response data
          } else {
            reject(new Error('Response data not available')); // Reject if response data is missing
          }
        })
        .catch((error) => {
          console.error('Error making API call:', error);
          if (error.response && error.response.status === 403) {
            Auth.removeAccessToken();
          }
          reject(error); // Reject the promise with the error
        });
    });
  };
export default postRegion;