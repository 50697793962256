import { ReactElement, useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useBusinessStore } from '../../services/store/businessStore';
import getCountries from '../../services/network/CountryMngt/GetCountries';
import getRegions from '../../services/network/RegionsMngt/GetRegions';
import CountryRegionModal from '../../Components/Modal/CountryRegionModal';
import { Box, Fab, Typography } from '@mui/material';
import AddCountryModal from '../../Components/Modal/AddCountryModal';
import AddIcon from '@mui/icons-material/Add';

import { Navigate, useNavigate } from 'react-router-dom';

export const CountryMngt: React.FC = (): ReactElement => {
    const { countries, setSelectedCountry }= useBusinessStore();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // State to manage the modal open/close


    useEffect(() => {
          getCountries()
          getRegions()
      }, []);

      const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const navigate = useNavigate();

    const handleItemClick = (countryId?: string  ) => {

      setSelectedCountry(countries.find(e => e.id === countryId))
      navigate('/RegionMngt');
    };

    const [hoveredIndex, setHoveredIndex] = useState<any>(null);
  
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
          <Typography variant="h5" gutterBottom>
            List of Countries
          </Typography>
          {countries.map((country, index) => (
            <Paper
            key={index}
            onClick={() => handleItemClick(country.id)}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            style={{
              margin: '8px',
              padding: '16px',
              width: '50%',
              textAlign: 'center',
              backgroundColor: hoveredIndex === index ? 'gray' : '#2196f3', // Change color on hover
              color: '#ffffff', // white color
              borderRadius: '8px',
              cursor: 'pointer', // Make it clear that this is clickable
              transition: 'background-color 0.3s, color 0.3s', // Smooth transition for the hover effect
              display: 'flex', // To align items horizontally
              alignItems: 'center', // Vertically center the items
              justifyContent: 'center', // Center the items horizontally
            }}
            elevation={3}
          >
                 <img
                  src={`https://flagcdn.com/32x24/${country.flagCode?.toLocaleLowerCase()}.png`}
                  alt={country.name}
                />   
              <Typography variant="h6" style={{marginLeft:'1rem'}}>
                {hoveredIndex === index ? 'Check regions' : country.name}
              </Typography>
            </Paper>
          ))}
        </div>
        <Fab
                color="primary"
                aria-label="add"
                onClick={openModal}
                sx={{
                    position: 'fixed',
                    bottom: '5vh',
                    right: '5vw',
                    zIndex: 9999, // Ensure it's above other elements
                }}
            >
                <AddIcon />
            </Fab>
        <AddCountryModal isOpen={isModalOpen} onClose={closeModal} />
      </>
    );
  };