import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Modal, Select, SelectChangeEvent, Typography } from '@mui/material';
import { BusinessOutputDto, ConsumerUserOutputDto, EventGroupOutputDto } from '../../services/network/autogenerated/Api';
import postGroup from '../../services/network/GroupMngt/PostGroup';

interface AddParticipantsToGroupModalProps {
    open: boolean;
    onClose: () => void;
    eventId : string;
    availableParticipants: ConsumerUserOutputDto[];
    business: BusinessOutputDto[];
    alreadySignedParticipants? : ConsumerUserOutputDto[]; //Strictly for edit mode
    selectedBusiness? :BusinessOutputDto; //Adicionar isto
}

const AddParticipantsToGroupModal: React.FC<AddParticipantsToGroupModalProps> = ({ open, onClose, eventId, availableParticipants, business }) => {
    const [selectedParticipants, setSelectedParticipants] = useState<string[]>([]);
    const [selectedBusiness, setSelectedBusiness] = useState<string>('');

    const handleParticipantChange = (participantId?: string) => {
        if(participantId){

            setSelectedParticipants((prev) =>
                prev.includes(participantId)
                    ? prev.filter(id => id !== participantId)
                    : [...prev, participantId]
            );
    }
    };

    const handleBusinessChange = (event: SelectChangeEvent<string>) => {
        setSelectedBusiness(event.target.value as string);
    };

    const handlePostUsers = () => {
        const data = {
            businessId: selectedBusiness,
            participants: selectedParticipants,
        };
        postGroup(eventId, data );
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Typography variant="h6" gutterBottom>Select Participants and Business</Typography>
                <List>
                    {availableParticipants.map((participant) => (
                        <ListItem key={participant.id}>
                            <Checkbox
                                checked={!!participant?.id && selectedParticipants.includes(participant?.id)}
                                onChange={() => handleParticipantChange(participant?.id)}
                            />
                            <ListItemText primary={participant.name} />
                        </ListItem>
                    ))}
                </List>
                <FormControl fullWidth>
                    <InputLabel id="business-select-label">Business</InputLabel>
                    <Select
                        labelId="business-select-label"
                        id="business-select"
                        value={selectedBusiness}
                        onChange={handleBusinessChange}
                    >
                        {business.map((bz) => (
                            <MenuItem key={bz?.id} value={bz?.id}>
                                {bz?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={handlePostUsers} style={{ marginTop: '20px' }}>
                    Create Group
                </Button>
            </Box>
        </Modal>
    );
};

const modalStyle = {
    position: 'absolute' as 'absolute',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default AddParticipantsToGroupModal;
