import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import {  Navigate, RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import { ErrorMngt } from './app/Screens/ErrorMngt';
import { Navigation } from './app/Screens/Navigation';
import { ThemeOptions, ThemeProvider } from '@mui/material';
import { LoginScreen } from './app/Screens/LoginScreen';
import { UserMngt } from './app/Screens/ChildScreens/UserMngt';
import { GameMngt } from './app/Screens/ChildScreens/GameMngt';
import { BusinessMngt } from './app/Screens/ChildScreens/BusinessMngt';
import { MediaMngt } from './app/Screens/ChildScreens/MediaMngt';
import { CountryMngt } from './app/Screens/ChildScreens/CountryMngt';
import { RegionMngt } from './app/Screens/ChildScreens/RegionMngt';
import {  EventMngt } from './app/Screens/ChildScreens/EventMngt';
import { EventDetails } from './app/Screens/ChildScreens/EventDetails';

const container = document.getElementById('root')!;
const root = createRoot(container);

const router = createHashRouter([
  {
    path: '/login',
    element: <LoginScreen />,
    errorElement: <ErrorMngt />,
  },
  {
    path: '/',
    element: <Navigation />,
    errorElement: <ErrorMngt />,
    children: [
      {
        path: '/',
        element: <Navigate to="events" />, // Redirect to /events
      },
      {
        path: 'events',
        element: <EventMngt />,
        errorElement: <ErrorMngt />,
      },
      { path:"events/:eventId",
       element:<EventDetails />,
        errorElement:<ErrorMngt /> 
      },
      {
        path: 'business',
        element: <BusinessMngt />,
        errorElement: <ErrorMngt />,
      },
      {
        path: 'CountryRegion',
        element: <CountryMngt />,
        errorElement: <ErrorMngt />,
      },
      {
        path: 'RegionMngt',
        element: <RegionMngt />,
        errorElement: <ErrorMngt />,
      },
      {
        path: 'games',
        element: <GameMngt />,
        errorElement: <ErrorMngt />,
      },
      {
        path: 'users',
        element: <UserMngt />,
        errorElement: <ErrorMngt />,
      },
      {
        path: 'media',
        element: <MediaMngt />,
        errorElement: <ErrorMngt />,
      }
     
    ],
  },
]);

export const themeOptions: ThemeOptions = {
  palette: {
      mode: 'light',
      primary: {
          main: '#FF8A00',
      },
      secondary: {
          main: '#F15A29',
      },
      background: {
          default: '#D9D9D9',
      },
      error: {
          main: '#DA1C5C',
      },
  },
};

root.render(
  <React.StrictMode>
      <ThemeProvider theme={themeOptions} />
      <RouterProvider router={router}/>
  </React.StrictMode>
);
