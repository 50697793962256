import { useBusinessStore } from '../../store/businessStore';
import { useMediaStore } from '../../store/mediaStore';
import { Api } from '../autogenerated/Api';
import { Auth } from '../baseApiConfig';

const retrieveFiles = () => {

  const axiosConfig = {
    baseURL: process.env.REACT_APP_API_URL, 
    headers: {
        'Content-Type': 'application/json', 
        Authorization: `Bearer ${Auth.getAccessToken()}`, 
      },
  };

  const meltpotApiTest = new Api({...axiosConfig})
  meltpotApiTest.api.retrieveFilesUsingGet()
    .then((response) => {
        if(response.data?.files){
          useMediaStore.getState().setMediaFiles(response.data.files);
        }
       
      })
      .catch((error) => {
        console.error('Error making API call:', error);
        if (error.response && error.response.status === 403) {
           Auth.removeAccessToken();
         
        }
      });
};
export default retrieveFiles;