import { useBusinessStore } from '../../store/businessStore';
import { useEventStore } from '../../store/eventStore';
import { Api, EventGroupInputDto, EventGroupOutputDto, EventOutputDto, RegionInputDto, RegionOutputDto } from '../autogenerated/Api';
import { Auth } from '../baseApiConfig';

const postGroup = (eventId : string, data: EventGroupInputDto): Promise<EventGroupOutputDto> => {
    return new Promise((resolve, reject) => {
      const axiosConfig = {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getAccessToken()}`,
        },
      };
  
      const endpoint = new Api({ ...axiosConfig });
      endpoint.api
        .createEventGroupUsingPost(eventId, data)
        .then((response) => {
          if (response.data) {
            const prevEventState = useEventStore.getState().events.find(event => event.id === eventId)
            if(prevEventState?.groups){
                    prevEventState.groups =  [...prevEventState?.groups, response.data]
                    useEventStore.getState().updateEvent(prevEventState);
            } 

            resolve(response.data); // Resolve the promise with response data
          } else {
            reject(new Error('Response data not available')); // Reject if response data is missing
          }
        })
        .catch((error) => {
          console.error('Error making API call:', error);
          if (error.response && error.response.status === 403) {
            Auth.removeAccessToken();
          }
          reject(error); // Reject the promise with the error
        });
    });
  };
export default postGroup;