/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AuthPasswordDto */
export interface AuthPasswordDto {
  email?: string;
  password?: string;
}

/** AuthUserDto */
export interface AuthUserDto {
  token?: string;
}

/** AvailableEventOutputDto */
export interface AvailableEventOutputDto {
  /** @example "yyyy-MM-dd HH:mm:ss.SSS'Z'" */
  announcementDateTime?: string;
  /** @format int32 */
  capacity?: number;
  /** @example "yyyy-MM-dd HH:mm:ss.SSS'Z'" */
  dateTime?: string;
  /** @format uuid */
  id?: string;
  isRegistered?: boolean;
  /** @format int32 */
  numberOfParticipants?: number;
  region?: RegionOutputDto;
  /** @format int32 */
  reservationPrice?: number;
  status?: "ACTIVE" | "CANCELLED" | "COMPLETED";
  type?: "BASIC" | "TRIVIA";
}

/** BackofficeUserInputDto */
export interface BackofficeUserInputDto {
  email?: string;
  name?: string;
  password?: string;
}

/** BackofficeUserOutputDto */
export interface BackofficeUserOutputDto {
  /** @example "yyyy-MM-dd HH:mm:ss.SSS'Z'" */
  creationDate?: string;
  email?: string;
  /** @example "yyyy-MM-dd HH:mm:ss.SSS'Z'" */
  lastAccess?: string;
  name?: string;
}

/** BusinessInputDto */
export interface BusinessInputDto {
  email?: string;
  imageUrl?: string;
  location?: string;
  locationUrl?: string;
  name?: string;
  owners?: string[];
  phoneNumber?: string;
  /** @format uuid */
  regionId?: string;
}

/** BusinessOutputDto */
export interface BusinessOutputDto {
  email?: string;
  /** @format uuid */
  id?: string;
  imageUrl?: string;
  location?: string;
  locationUrl?: string;
  name?: string;
  owners?: BusinessUserOutputDto[];
  phoneNumber?: string;
  region?: RegionOutputDto;
}

/** BusinessUserInputDto */
export interface BusinessUserInputDto {
  /** @format uuid */
  businessId?: string;
  email?: string;
  password?: string;
}

/** BusinessUserOutputDto */
export interface BusinessUserOutputDto {
  email?: string;
  /** @format uuid */
  id?: string;
  password?: string;
}

/** ConsumerUserInputDto */
export interface ConsumerUserInputDto {
  /** @example "dd-MM-yyyy" */
  birthdate?: string;
  email?: string;
  gender?: "Female" | "Male" | "Other";
  name?: string;
  password?: string;
}

/** ConsumerUserOutputDto */
export interface ConsumerUserOutputDto {
  /** @example "dd-MM-yyyy" */
  birthdate?: string;
  email?: string;
  gender?: "Female" | "Male" | "Other";
  /** @format uuid */
  id?: string;
  name?: string;
}

/** CountryInputDto */
export interface CountryInputDto {
  flagCode?: string;
  name?: string;
}

/** CountryOutputDto */
export interface CountryOutputDto {
  flagCode?: string;
  /** @format uuid */
  id?: string;
  name?: string;
}

/** EventGroupInputDto */
export interface EventGroupInputDto {
  /** @format uuid */
  businessId?: string;
  /** @format int32 */
  groupNumber?: number;
  participants?: string[];
}

/** EventGroupOutputDto */
export interface EventGroupOutputDto {
  business?: BusinessOutputDto;
  /** @format int32 */
  groupNumber?: number;
  /** @format uuid */
  id?: string;
  participants?: ConsumerUserOutputDto[];
}

/** EventInputDto */
export interface EventInputDto {
  /** @example "yyyy-MM-dd HH:mm:ss.SSS'Z'" */
  announcementDateTime?: string;
  /** @format int32 */
  capacity?: number;
  /** @example "yyyy-MM-dd HH:mm:ss.SSS'Z'" */
  dateTime?: string;
  /** @format uuid */
  regionId?: string;
  /** @format int32 */
  reservationPrice?: number;
}

/** EventOutputDto */
export interface EventOutputDto {
  /** @example "yyyy-MM-dd HH:mm:ss.SSS'Z'" */
  announcementDateTime?: string;
  /** @format int32 */
  capacity?: number;
  /** @example "yyyy-MM-dd HH:mm:ss.SSS'Z'" */
  dateTime?: string;
  groups?: EventGroupOutputDto[];
  /** @format uuid */
  id?: string;
  isRegistered?: boolean;
  /** @format int32 */
  numberOfParticipants?: number;
  participants?: ConsumerUserOutputDto[];
  region?: RegionOutputDto;
  /** @format int32 */
  reservationPrice?: number;
  reviewed?: boolean;
  status?: "ACTIVE" | "CANCELLED" | "COMPLETED";
  type?: "BASIC" | "TRIVIA";
}

/** FeedbackInputDto */
export interface FeedbackInputDto {
  description?: string;
  /** @format int32 */
  eventRate?: number;
  /** @format int32 */
  groupRate?: number;
}

/** FeedbackOutputDto */
export interface FeedbackOutputDto {
  description?: string;
  /** @format int32 */
  eventRate?: number;
  /** @format int32 */
  groupRate?: number;
  /** @format uuid */
  id?: string;
}

/** GameOutputDto */
export interface GameOutputDto {
  description?: string;
  /** @format uuid */
  id?: string;
  name?: string;
  type?: string;
}

/** GuessUpGameInputDto */
export interface GuessUpGameInputDto {
  /** @uniqueItems true */
  words?: TranslatableTextInputDto[];
}

/** GuessUpGameOutputDto */
export interface GuessUpGameOutputDto {
  /** @format uuid */
  id?: string;
  words?: string[];
}

/** HistoryEventOutputDto */
export interface HistoryEventOutputDto {
  business?: BusinessOutputDto;
  /** @example "yyyy-MM-dd HH:mm:ss.SSS'Z'" */
  dateTime?: string;
  /** @format uuid */
  id?: string;
  region?: RegionOutputDto;
  /** @format int32 */
  reservationPrice?: number;
  reviewed?: boolean;
  status?: "ACTIVE" | "CANCELLED" | "COMPLETED";
  type?: "BASIC" | "TRIVIA";
}

/** ModelAndView */
export interface ModelAndView {
  empty?: boolean;
  model?: object;
  modelMap?: Record<string, object>;
  reference?: boolean;
  status?:
    | "ACCEPTED"
    | "ALREADY_REPORTED"
    | "BAD_GATEWAY"
    | "BAD_REQUEST"
    | "BANDWIDTH_LIMIT_EXCEEDED"
    | "CHECKPOINT"
    | "CONFLICT"
    | "CONTINUE"
    | "CREATED"
    | "DESTINATION_LOCKED"
    | "EXPECTATION_FAILED"
    | "FAILED_DEPENDENCY"
    | "FORBIDDEN"
    | "FOUND"
    | "GATEWAY_TIMEOUT"
    | "GONE"
    | "HTTP_VERSION_NOT_SUPPORTED"
    | "IM_USED"
    | "INSUFFICIENT_SPACE_ON_RESOURCE"
    | "INSUFFICIENT_STORAGE"
    | "INTERNAL_SERVER_ERROR"
    | "I_AM_A_TEAPOT"
    | "LENGTH_REQUIRED"
    | "LOCKED"
    | "LOOP_DETECTED"
    | "METHOD_FAILURE"
    | "METHOD_NOT_ALLOWED"
    | "MOVED_PERMANENTLY"
    | "MOVED_TEMPORARILY"
    | "MULTIPLE_CHOICES"
    | "MULTI_STATUS"
    | "NETWORK_AUTHENTICATION_REQUIRED"
    | "NON_AUTHORITATIVE_INFORMATION"
    | "NOT_ACCEPTABLE"
    | "NOT_EXTENDED"
    | "NOT_FOUND"
    | "NOT_IMPLEMENTED"
    | "NOT_MODIFIED"
    | "NO_CONTENT"
    | "OK"
    | "PARTIAL_CONTENT"
    | "PAYLOAD_TOO_LARGE"
    | "PAYMENT_REQUIRED"
    | "PERMANENT_REDIRECT"
    | "PRECONDITION_FAILED"
    | "PRECONDITION_REQUIRED"
    | "PROCESSING"
    | "PROXY_AUTHENTICATION_REQUIRED"
    | "REQUESTED_RANGE_NOT_SATISFIABLE"
    | "REQUEST_ENTITY_TOO_LARGE"
    | "REQUEST_HEADER_FIELDS_TOO_LARGE"
    | "REQUEST_TIMEOUT"
    | "REQUEST_URI_TOO_LONG"
    | "RESET_CONTENT"
    | "SEE_OTHER"
    | "SERVICE_UNAVAILABLE"
    | "SWITCHING_PROTOCOLS"
    | "TEMPORARY_REDIRECT"
    | "TOO_EARLY"
    | "TOO_MANY_REQUESTS"
    | "UNAUTHORIZED"
    | "UNAVAILABLE_FOR_LEGAL_REASONS"
    | "UNPROCESSABLE_ENTITY"
    | "UNSUPPORTED_MEDIA_TYPE"
    | "UPGRADE_REQUIRED"
    | "URI_TOO_LONG"
    | "USE_PROXY"
    | "VARIANT_ALSO_NEGOTIATES";
  view?: View;
  viewName?: string;
}

/** OngoingEventOutputDto */
export interface OngoingEventOutputDto {
  business?: BusinessOutputDto;
  /** @example "yyyy-MM-dd HH:mm:ss.SSS'Z'" */
  dateTime?: string;
  games?: GameOutputDto[];
  /** @format uuid */
  id?: string;
  region?: RegionOutputDto;
  status?: "ACTIVE" | "CANCELLED" | "COMPLETED";
  type?: "BASIC" | "TRIVIA";
}

/** RegionInputDto */
export interface RegionInputDto {
  /** @format uuid */
  countryId?: string;
  imageUrl?: string;
  name?: string;
}

/** RegionOutputDto */
export interface RegionOutputDto {
  country?: CountryOutputDto;
  /** @format uuid */
  id?: string;
  imageUrl?: string;
  name?: string;
}

/** ResendVerificationTokenDto */
export interface ResendVerificationTokenDto {
  email?: string;
  password?: string;
}

/** ResetPasswordCodeDto */
export interface ResetPasswordCodeDto {
  email?: string;
}

/** RetrieveAllEventsOutputDto */
export interface RetrieveAllEventsOutputDto {
  events?: EventOutputDto[];
}

/** RetrieveAvailableEventsOutputDto */
export interface RetrieveAvailableEventsOutputDto {
  events?: AvailableEventOutputDto[];
}

/** RetrieveBackofficeUsersOutputDto */
export interface RetrieveBackofficeUsersOutputDto {
  backofficeUsers?: BackofficeUserOutputDto[];
}

/** RetrieveFilesOutputDto */
export interface RetrieveFilesOutputDto {
  files?: string[];
}

/** RetrieveHistoryEventsOutputDto */
export interface RetrieveHistoryEventsOutputDto {
  events?: HistoryEventOutputDto[];
}

/** TranslatableTextInputDto */
export interface TranslatableTextInputDto {
  translations?: TranslationInputDto[];
}

/** TranslationInputDto */
export interface TranslationInputDto {
  idiom?: "EN" | "PT";
  text?: string;
}

/** TriviaAnswerOutputDto */
export interface TriviaAnswerOutputDto {
  answer?: string;
  /** @format uuid */
  id?: string;
  isCorrect?: boolean;
}

/** TriviaCategoryInputDto */
export interface TriviaCategoryInputDto {
  category?: TranslatableTextInputDto;
}

/** TriviaCategoryOutputDto */
export interface TriviaCategoryOutputDto {
  category?: string;
  /** @format uuid */
  id?: string;
}

/** TriviaGameInputDto */
export interface TriviaGameInputDto {
  questions?: string[];
}

/** TriviaGameOutputDto */
export interface TriviaGameOutputDto {
  /** @format uuid */
  id?: string;
  questions?: TriviaQuestionOutputDto[];
}

/** TriviaQuestionInputDto */
export interface TriviaQuestionInputDto {
  answers?: TranslatableTextInputDto[];
  /** @format uuid */
  categoryId?: string;
  imageUrl?: string;
  question?: TranslatableTextInputDto;
}

/** TriviaQuestionOutputDto */
export interface TriviaQuestionOutputDto {
  answers?: TriviaAnswerOutputDto[];
  category?: TriviaCategoryOutputDto;
  /** @format uuid */
  id?: string;
  imageUrl?: string;
  question?: string;
}

/** UpdateConsumerUserInputDto */
export interface UpdateConsumerUserInputDto {
  name?: string;
  newPassword?: string;
  password?: string;
}

/** VerifyUserDto */
export interface VerifyUserDto {
  code?: string;
  email?: string;
  password?: string;
}

/** View */
export interface View {
  contentType?: string;
}

/** WhatAmIGameInputDto */
export interface WhatAmIGameInputDto {
  /** @uniqueItems true */
  words?: TranslatableTextInputDto[];
}

/** WhatAmIGameOutputDto */
export interface WhatAmIGameOutputDto {
  /** @format uuid */
  id?: string;
  words?: string[];
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "https://api.meltpot.pt" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Api Documentation
 * @version 1.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 * @termsOfService urn:tos
 * @baseUrl http://localhost:8080
 * @contact
 *
 * Api Documentation
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags auth-controller
     * @name LoginUsingPost
     * @summary login
     * @request POST:/api/v1/auth/login
     */
    loginUsingPost: (data: AuthPasswordDto, params: RequestParams = {}) =>
      this.request<AuthUserDto, void>({
        path: `/api/v1/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags backoffice-controller
     * @name DeleteUserUsingDelete
     * @summary deleteUser
     * @request DELETE:/api/v1/backoffice/delete/{userId}
     */
    deleteUserUsingDelete: (
      userId: string,
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/v1/backoffice/delete/${userId}`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags backoffice-controller
     * @name RegisterBackofficeUserUsingPost
     * @summary registerBackofficeUser
     * @request POST:/api/v1/backoffice/register
     */
    registerBackofficeUserUsingPost: (data: BackofficeUserInputDto, params: RequestParams = {}) =>
      this.request<AuthUserDto, void>({
        path: `/api/v1/backoffice/register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags backoffice-controller
     * @name RetrieveBackofficeUsersUsingGet
     * @summary retrieveBackofficeUsers
     * @request GET:/api/v1/backoffice/users
     */
    retrieveBackofficeUsersUsingGet: (params: RequestParams = {}) =>
      this.request<RetrieveBackofficeUsersOutputDto, void>({
        path: `/api/v1/backoffice/users`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags business-controller
     * @name CreateBusinessUsingPost
     * @summary createBusiness
     * @request POST:/api/v1/business
     */
    createBusinessUsingPost: (data: BusinessInputDto, params: RequestParams = {}) =>
      this.request<BusinessOutputDto, void>({
        path: `/api/v1/business`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags business-controller
     * @name RetrieveBusinessesUsingGet
     * @summary retrieveBusinesses
     * @request GET:/api/v1/business/all
     */
    retrieveBusinessesUsingGet: (params: RequestParams = {}) =>
      this.request<BusinessOutputDto[], void>({
        path: `/api/v1/business/all`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags business-controller
     * @name RegisterUserUsingPost
     * @summary registerUser
     * @request POST:/api/v1/business/register
     */
    registerUserUsingPost: (data: BusinessUserInputDto, params: RequestParams = {}) =>
      this.request<AuthUserDto, void>({
        path: `/api/v1/business/register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags business-controller
     * @name RetrieveBusinessUsingGet
     * @summary retrieveBusiness
     * @request GET:/api/v1/business/{businessId}
     */
    retrieveBusinessUsingGet: (businessId: string, params: RequestParams = {}) =>
      this.request<BusinessOutputDto, void>({
        path: `/api/v1/business/${businessId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags business-controller
     * @name DeleteBusinessUsingDelete
     * @summary deleteBusiness
     * @request DELETE:/api/v1/business/{businessId}
     */
    deleteBusinessUsingDelete: (businessId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/business/${businessId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags business-controller
     * @name UpdateBusinessUsingPatch
     * @summary updateBusiness
     * @request PATCH:/api/v1/business/{businessId}
     */
    updateBusinessUsingPatch: (businessId: string, data: BusinessInputDto, params: RequestParams = {}) =>
      this.request<BusinessOutputDto, void>({
        path: `/api/v1/business/${businessId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags consumer-controller
     * @name RetrieveUserUsingGet
     * @summary retrieveUser
     * @request GET:/api/v1/consumer
     */
    retrieveUserUsingGet: (
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConsumerUserOutputDto, void>({
        path: `/api/v1/consumer`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags consumer-controller
     * @name DeleteUserUsingDelete1
     * @summary deleteUser
     * @request DELETE:/api/v1/consumer
     */
    deleteUserUsingDelete1: (
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/v1/consumer`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags consumer-controller
     * @name UpdateUserUsingPatch
     * @summary updateUser
     * @request PATCH:/api/v1/consumer
     */
    updateUserUsingPatch: (
      data: UpdateConsumerUserInputDto,
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConsumerUserOutputDto, void>({
        path: `/api/v1/consumer`,
        method: "PATCH",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags consumer-controller
     * @name ResetPasswordUsingPatch
     * @summary resetPassword
     * @request PATCH:/api/v1/consumer/password/reset
     */
    resetPasswordUsingPatch: (data: VerifyUserDto, params: RequestParams = {}) =>
      this.request<AuthUserDto, void>({
        path: `/api/v1/consumer/password/reset`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags consumer-controller
     * @name GenerateResetPasswordCodeUsingPatch
     * @summary generateResetPasswordCode
     * @request PATCH:/api/v1/consumer/password/reset/code
     */
    generateResetPasswordCodeUsingPatch: (data: ResetPasswordCodeDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/consumer/password/reset/code`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags consumer-controller
     * @name RegisterUserUsingPost1
     * @summary registerUser
     * @request POST:/api/v1/consumer/register
     */
    registerUserUsingPost1: (data: ConsumerUserInputDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/consumer/register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags consumer-controller
     * @name VerifyUserUsingPatch
     * @summary verifyUser
     * @request PATCH:/api/v1/consumer/verify
     */
    verifyUserUsingPatch: (data: VerifyUserDto, params: RequestParams = {}) =>
      this.request<AuthUserDto, void>({
        path: `/api/v1/consumer/verify`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags consumer-controller
     * @name ResendVerificationTokenUsingPatch
     * @summary resendVerificationToken
     * @request PATCH:/api/v1/consumer/verify/resend
     */
    resendVerificationTokenUsingPatch: (data: ResendVerificationTokenDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/consumer/verify/resend`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name CreateEventUsingPost
     * @summary createEvent
     * @request POST:/api/v1/event
     */
    createEventUsingPost: (data: EventInputDto, params: RequestParams = {}) =>
      this.request<EventOutputDto, void>({
        path: `/api/v1/event`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name RetrieveAllEventsUsingGet
     * @summary retrieveAllEvents
     * @request GET:/api/v1/event/all
     */
    retrieveAllEventsUsingGet: (
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RetrieveAllEventsOutputDto, void>({
        path: `/api/v1/event/all`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name RetrieveAvailableEventsUsingGet
     * @summary retrieveAvailableEvents
     * @request GET:/api/v1/event/available
     */
    retrieveAvailableEventsUsingGet: (
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RetrieveAvailableEventsOutputDto, void>({
        path: `/api/v1/event/available`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name CheckoutEventUsingPost
     * @summary checkoutEvent
     * @request POST:/api/v1/event/checkout/{eventId}
     */
    checkoutEventUsingPost: (
      eventId: string,
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/v1/event/checkout/${eventId}`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name RetrieveAvailableCountriesUsingGet
     * @summary retrieveAvailableCountries
     * @request GET:/api/v1/event/country
     */
    retrieveAvailableCountriesUsingGet: (params: RequestParams = {}) =>
      this.request<CountryOutputDto[], void>({
        path: `/api/v1/event/country`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name AddCountryUsingPost
     * @summary addCountry
     * @request POST:/api/v1/event/country
     */
    addCountryUsingPost: (data: CountryInputDto, params: RequestParams = {}) =>
      this.request<CountryOutputDto, void>({
        path: `/api/v1/event/country`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name UpdateEventGroupUsingPatch
     * @summary updateEventGroup
     * @request PATCH:/api/v1/event/group/{groupId}
     */
    updateEventGroupUsingPatch: (groupId: string, data: EventGroupInputDto, params: RequestParams = {}) =>
      this.request<EventGroupOutputDto, void>({
        path: `/api/v1/event/group/${groupId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name RetrieveHistoryEventsUsingGet
     * @summary retrieveHistoryEvents
     * @request GET:/api/v1/event/history
     */
    retrieveHistoryEventsUsingGet: (
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RetrieveHistoryEventsOutputDto, void>({
        path: `/api/v1/event/history`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name RetrieveAvailableRegionsUsingGet
     * @summary retrieveAvailableRegions
     * @request GET:/api/v1/event/region
     */
    retrieveAvailableRegionsUsingGet: (params: RequestParams = {}) =>
      this.request<RegionOutputDto[], void>({
        path: `/api/v1/event/region`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name AddRegionUsingPost
     * @summary addRegion
     * @request POST:/api/v1/event/region
     */
    addRegionUsingPost: (data: RegionInputDto, params: RequestParams = {}) =>
      this.request<RegionOutputDto, void>({
        path: `/api/v1/event/region`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name UpdateRegionUsingPatch
     * @summary updateRegion
     * @request PATCH:/api/v1/event/region/{regionId}
     */
    updateRegionUsingPatch: (regionId: string, data: RegionInputDto, params: RequestParams = {}) =>
      this.request<RegionOutputDto, void>({
        path: `/api/v1/event/region/${regionId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name SubscribeEventUsingPost
     * @summary subscribeEvent
     * @request POST:/api/v1/event/subscribe/{eventId}
     */
    subscribeEventUsingPost: (
      eventId: string,
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/v1/event/subscribe/${eventId}`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name RetrieveEventUsingGet
     * @summary retrieveEvent
     * @request GET:/api/v1/event/{eventId}
     */
    retrieveEventUsingGet: (
      eventId: string,
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EventOutputDto, void>({
        path: `/api/v1/event/${eventId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name UpdateEventUsingPatch
     * @summary updateEvent
     * @request PATCH:/api/v1/event/{eventId}
     */
    updateEventUsingPatch: (eventId: string, data: EventInputDto, params: RequestParams = {}) =>
      this.request<EventOutputDto, void>({
        path: `/api/v1/event/${eventId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name CreateEventGroupUsingPost
     * @summary createEventGroup
     * @request POST:/api/v1/event/{eventId}/group
     */
    createEventGroupUsingPost: (eventId: string, data: EventGroupInputDto, params: RequestParams = {}) =>
      this.request<EventGroupOutputDto, void>({
        path: `/api/v1/event/${eventId}/group`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-controller
     * @name RetrieveOngoingEventUsingGet
     * @summary retrieveOngoingEvent
     * @request GET:/api/v1/event/{eventId}/ongoing
     */
    retrieveOngoingEventUsingGet: (
      eventId: string,
      query: {
        name?: string;
        /** idiom */
        idiom: "EN" | "PT";
      },
      params: RequestParams = {},
    ) =>
      this.request<OngoingEventOutputDto, void>({
        path: `/api/v1/event/${eventId}/ongoing`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feedback-controller
     * @name RetrieveReviewsUsingGet
     * @summary retrieveReviews
     * @request GET:/api/v1/feedback/{eventId}
     */
    retrieveReviewsUsingGet: (eventId: string, params: RequestParams = {}) =>
      this.request<FeedbackOutputDto[], void>({
        path: `/api/v1/feedback/${eventId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags feedback-controller
     * @name AddReviewUsingPost
     * @summary addReview
     * @request POST:/api/v1/feedback/{eventId}
     */
    addReviewUsingPost: (eventId: string, data: FeedbackInputDto, params: RequestParams = {}) =>
      this.request<FeedbackOutputDto, void>({
        path: `/api/v1/feedback/${eventId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name RetrieveGuessUpGameUsingGet
     * @summary retrieveGuessUpGame
     * @request GET:/api/v1/game/guessup/{eventId}
     */
    retrieveGuessUpGameUsingGet: (
      eventId: string,
      query: {
        /** idiom */
        idiom: "EN" | "PT";
      },
      params: RequestParams = {},
    ) =>
      this.request<GuessUpGameOutputDto, void>({
        path: `/api/v1/game/guessup/${eventId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name CreateGuessUpGameUsingPost
     * @summary createGuessUpGame
     * @request POST:/api/v1/game/guessup/{eventId}
     */
    createGuessUpGameUsingPost: (eventId: string, data: GuessUpGameInputDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/guessup/${eventId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name UpdateGuessUpGameUsingPatch
     * @summary updateGuessUpGame
     * @request PATCH:/api/v1/game/guessup/{eventId}
     */
    updateGuessUpGameUsingPatch: (eventId: string, data: GuessUpGameInputDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/guessup/${eventId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name CreateTriviaCategoryUsingPost
     * @summary createTriviaCategory
     * @request POST:/api/v1/game/trivia/category
     */
    createTriviaCategoryUsingPost: (data: TriviaCategoryInputDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/trivia/category`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name UpdateTriviaCategoryUsingPatch
     * @summary updateTriviaCategory
     * @request PATCH:/api/v1/game/trivia/category
     */
    updateTriviaCategoryUsingPatch: (data: TriviaCategoryInputDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/trivia/category`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name DeleteTriviaCategoryUsingDelete
     * @summary deleteTriviaCategory
     * @request DELETE:/api/v1/game/trivia/category/{categoryId}
     */
    deleteTriviaCategoryUsingDelete: (categoryId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/trivia/category/${categoryId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name RetrieveTriviaQuestionsUsingGet
     * @summary retrieveTriviaQuestions
     * @request GET:/api/v1/game/trivia/question
     */
    retrieveTriviaQuestionsUsingGet: (params: RequestParams = {}) =>
      this.request<TriviaQuestionOutputDto[], void>({
        path: `/api/v1/game/trivia/question`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name CreateTriviaQuestionUsingPost
     * @summary createTriviaQuestion
     * @request POST:/api/v1/game/trivia/question
     */
    createTriviaQuestionUsingPost: (data: TriviaQuestionInputDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/trivia/question`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name DeleteTriviaQuestionUsingDelete
     * @summary deleteTriviaQuestion
     * @request DELETE:/api/v1/game/trivia/question
     */
    deleteTriviaQuestionUsingDelete: (questionId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/trivia/question`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name UpdateTriviaQuestionUsingPatch
     * @summary updateTriviaQuestion
     * @request PATCH:/api/v1/game/trivia/question
     */
    updateTriviaQuestionUsingPatch: (data: TriviaQuestionInputDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/trivia/question`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name RetrieveTriviaGameUsingGet
     * @summary retrieveTriviaGame
     * @request GET:/api/v1/game/trivia/{eventId}
     */
    retrieveTriviaGameUsingGet: (
      eventId: string,
      query: {
        /** idiom */
        idiom: "EN" | "PT";
      },
      params: RequestParams = {},
    ) =>
      this.request<TriviaGameOutputDto, void>({
        path: `/api/v1/game/trivia/${eventId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name CreateTriviaGameUsingPost
     * @summary createTriviaGame
     * @request POST:/api/v1/game/trivia/{eventId}
     */
    createTriviaGameUsingPost: (eventId: string, data: TriviaGameInputDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/trivia/${eventId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name UpdateTriviaGameUsingPatch
     * @summary updateTriviaGame
     * @request PATCH:/api/v1/game/trivia/{eventId}
     */
    updateTriviaGameUsingPatch: (eventId: string, data: TriviaGameInputDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/trivia/${eventId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name RetrieveWhatAmIGameUsingGet
     * @summary retrieveWhatAmIGame
     * @request GET:/api/v1/game/whatami/{eventId}
     */
    retrieveWhatAmIGameUsingGet: (
      eventId: string,
      query: {
        /** idiom */
        idiom: "EN" | "PT";
      },
      params: RequestParams = {},
    ) =>
      this.request<WhatAmIGameOutputDto, void>({
        path: `/api/v1/game/whatami/${eventId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name CreateWhatAmIGameUsingPost
     * @summary createWhatAmIGame
     * @request POST:/api/v1/game/whatami/{eventId}
     */
    createWhatAmIGameUsingPost: (eventId: string, data: WhatAmIGameInputDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/whatami/${eventId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-controller
     * @name UpdateWhatAmIGameUsingPatch
     * @summary updateWhatAmIGame
     * @request PATCH:/api/v1/game/whatami/{eventId}
     */
    updateWhatAmIGameUsingPatch: (eventId: string, data: WhatAmIGameInputDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/game/whatami/${eventId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags storage-controller
     * @name DownloadFileUsingGet
     * @summary downloadFile
     * @request GET:/api/v1/storage/download/{fileName}
     */
    downloadFileUsingGet: (fileName: string, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/api/v1/storage/download/${fileName}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags storage-controller
     * @name RetrieveFilesUsingGet
     * @summary retrieveFiles
     * @request GET:/api/v1/storage/files
     */
    retrieveFilesUsingGet: (params: RequestParams = {}) =>
      this.request<RetrieveFilesOutputDto, void>({
        path: `/api/v1/storage/files`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags storage-controller
     * @name UploadFileUsingPost
     * @summary uploadFile
     * @request POST:/api/v1/storage/upload
     */
    uploadFileUsingPost: (data: FormData, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/storage/upload`,
        method: "POST",
        body: data,
        ...params,
      }),
  };
  error = {
    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingGet
     * @summary error
     * @request GET:/error
     */
    errorUsingGet: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingPut
     * @summary error
     * @request PUT:/error
     */
    errorUsingPut: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "PUT",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingPost
     * @summary error
     * @request POST:/error
     */
    errorUsingPost: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingDelete
     * @summary error
     * @request DELETE:/error
     */
    errorUsingDelete: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingOptions
     * @summary error
     * @request OPTIONS:/error
     */
    errorUsingOptions: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "OPTIONS",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingHead
     * @summary error
     * @request HEAD:/error
     */
    errorUsingHead: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "HEAD",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingPatch
     * @summary error
     * @request PATCH:/error
     */
    errorUsingPatch: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "PATCH",
        ...params,
      }),

    /**
     * No description
     *
     * @tags basic-error-controller
     * @name ErrorUsingTrace
     * @summary error
     * @request TRACE:/error
     */
    errorUsingTrace: (params: RequestParams = {}) =>
      this.request<Record<string, object>, void>({
        path: `/error`,
        method: "TRACE",
        ...params,
      }),
  };
}
