import { useBusinessStore } from '../../store/businessStore';
import { useMediaStore } from '../../store/mediaStore';
import { Api, RetrieveFilesOutputDto } from '../autogenerated/Api';
import { Auth } from '../baseApiConfig';

const uploadFile = ( data: File) =>  {

  const axiosConfig = {
    baseURL: process.env.REACT_APP_API_URL, 
    headers: {
        Authorization: `Bearer ${Auth.getAccessToken()}`, 
      },
  };

  const meltpotApiTest = new Api({...axiosConfig})
  const formData = new FormData();
    formData.append('file', data);
  meltpotApiTest.api.uploadFileUsingPost(formData)
    .then(() => {
      })
      .catch((error) => {
        console.error('Error making API call:', error);
        if (error.response && error.response.status === 403) {
           Auth.removeAccessToken();
         
        }
      });
};
export default uploadFile;