import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import { useRouteError } from 'react-router';

export const ErrorMngt: React.FC = (): ReactElement => {
    const error: any = useRouteError();

    console.error(error);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                textAlign: 'center',
            }}
        >
            <Box
                component="img"
                src="https://i.pinimg.com/originals/a4/62/d1/a462d192479048db0f02f4466b900e0a.gif"
                alt="Error Gif"
                sx={{ width: '200px', mb: '20px' }}
            />
            <Typography variant="h4" component="h1" mb={2}>
                Oops! Something went wrong!
            </Typography>
            <Typography variant="body1" mb={2}>
                It seems like the page you were looking for doesn't exist.
            </Typography>
            <Typography variant="body2" fontStyle="italic">
                {error.statusText || error.message}
            </Typography>
        </Box>
    );
};
