import React, { useState } from 'react';
import { Modal, TextField, Button, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import postBusiness from '../../services/network/BusinessMngt/PostBusiness';
import { useBusinessStore } from '../../services/store/businessStore';

//Todo add Edit Business feature.

interface CreateBusinessProps {
    isOpen: boolean;
    onClose: () => void;
  }
  
const BusinessModal: React.FC<CreateBusinessProps>  = ({ isOpen, onClose }) => {
   const {regions} = useBusinessStore();
  const [businessData, setBusinessData] = useState({
    email: '',
    imageUrl: '',
    location: '',
    locationUrl: '',
    name: '',
    phoneNumber: '',
    owners: [],
    regionId: '',
  });

  const handleChange = (e : any) => {
    const { name, value } = e.target;
    setBusinessData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreate = () => {
    postBusiness(businessData) .then(() => {
        onClose(); 
    })
    .catch((error) => {
        console.error('Error posting Business:', error);
        
    });
  };

  const isCreateButtonDisabled = !(
    businessData.name &&
    businessData.phoneNumber &&
    businessData.email &&
    businessData.location &&
    businessData.locationUrl
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="business-modal-title"
      aria-describedby="business-modal-description"
    >
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
        <h2 id="business-modal-title">Create Business</h2>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Business Name"
            name="name"
            value={businessData.name}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Email"
            name="email"
            value={businessData.email}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Image URL"
            name="imageUrl"
            value={businessData.imageUrl}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Location"
            name="location"
            value={businessData.location}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Location URL"
            name="locationUrl"
            value={businessData.locationUrl}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={businessData.phoneNumber}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="region-select-label">Region</InputLabel>
          <Select
            labelId="region-select-label"
            id="region-select"
            name="regionId"
            value={businessData.regionId}
            onChange={handleChange}
          >
            {regions.map((region) => (
              <MenuItem key={region.id} value={region.id}>
                {region.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleCreate} variant="contained" color="primary" disabled={isCreateButtonDisabled}>Create</Button>
      </div>
    </Modal>
  );
};

export default BusinessModal;