import { create } from "zustand";
import { BusinessOutputDto, CountryOutputDto, RegionOutputDto } from "../network/autogenerated/Api";
import { devtools } from "zustand/middleware";
import { count } from "console";

interface BusinessStoreState {
    business: BusinessOutputDto[];
    countries : CountryOutputDto[];
    regions :RegionOutputDto[];
    selectedCountry : CountryOutputDto | null;

}
interface BusinessStoreActions {
    setBusiness: (business: BusinessOutputDto[]) => void;
    createBusiness:(business: BusinessOutputDto) => void;
    updateBusiness:(updatedBusiness:BusinessOutputDto)=> void;
    deleteBusiness: (userEmail: string) => void;
    setRegions: (regionList: RegionOutputDto[]) => void;
    createRegion:(region: RegionOutputDto) => void;
    updateRegion:(updatedRegion: RegionOutputDto) => void;
    setCountries: (countries: CountryOutputDto[]) => void;
    createCountry:(country: CountryOutputDto) => void;
    updateCountry:(updatedCountry: CountryOutputDto) => void;
    setSelectedCountry:(countryId?: CountryOutputDto) =>void

}

export const useBusinessStore = create<BusinessStoreState & BusinessStoreActions>()(
    devtools(
        (set) => ({
          business: [],
          countries:[],
          regions:[],
          selectedCountry : null,
          setCountries:(countryList : CountryOutputDto[]) =>set(() => ({ countries: [...countryList] }),false,'setCountries'),
          createCountry: (country: CountryOutputDto) => set((state) => ({ countries: [...state.countries, country] }), false, 'createCountry'),
          updateCountry: (updatedCountry: RegionOutputDto) => set((state) => ({
            countries: state.countries.map(country =>
              country.id === updatedCountry.id ? updatedCountry : country
            )
        }), false, 'updateCountry'),
          setRegions:(regionList : RegionOutputDto[]) =>set(() => ({ regions: [...regionList] }),false,'setRegions'),
          createRegion: (region: RegionOutputDto) => set((state) => ({ regions: [...state.regions, region] }), false, 'createRegion'),
          updateRegion: (updatedRegion: RegionOutputDto) => set((state) => ({
            regions: state.regions.map(region =>
                region.id === updatedRegion.id ? updatedRegion : region
            )
        }), false, 'updateRegion'),
          setBusiness: (businessList: BusinessOutputDto[]) => set(() => ({ business: [...businessList] }),false,'setBusiness'),
          createBusiness: (business: BusinessOutputDto) => set((state) => ({ business: [...state.business, business] }), false, 'createBusiness'),
          deleteBusiness: (businessId: string) => set((state) => ({ business: state.business.filter(business => business.id !== businessId) }), false,'deleteBusiness'),
          updateBusiness: (updatedBusiness: BusinessOutputDto) => set((state) => ({
            business: state.business.map(business =>
                business.id === updatedBusiness.id ? updatedBusiness : business
            )
        }), false, 'updateBusiness'),
        setSelectedCountry: (country?: CountryOutputDto) => set(() => ({ selectedCountry : {...country} }),false,'setSelectedCountry')
        }),
        {
          name: 'Meltpot Business DEV', // Specify a name for devtools
          enabled : true,
    trace :true,
    serialize: true, // Whether to serialize state changes
    logArgs: false, // Whether to log arguments
        }
      )
    );