import { ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Auth } from '../services/network/baseApiConfig';
import { useNavigate } from 'react-router-dom';
import logo from '../../meltpot-logo.gif';
import { useTheme } from '@emotion/react';
import { Api } from '../services/network/autogenerated/Api';

export const LoginScreen: React.FC = (): ReactElement => {
    
    const axiosConfig = {
        baseURL: process.env.REACT_APP_API_URL, 
        headers: {
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${Auth.getAccessToken()}`, 
          },
      };
      const endpoint = new Api({...axiosConfig})

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        setEmailError(false);
        setPasswordError(false);

        if (email === '') {
            setEmailError(true);
        }
        if (password === '') {
            setPasswordError(true);
        }

        if (email && password) {
            endpoint.api
                .loginUsingPost({ email, password })
                .then((response) => {
                    if (response?.data?.token) {
                        Auth.storeAccessToken(response.data.token);
                        navigate('/events');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <Box
                component="img"
                src={logo}
                alt="Logo"
                sx={{ width: '200px', mb: '20px' }}
            />
            <Typography variant="h2" component="h2" sx={{ mb: '20px' }}>
                BackOffice
            </Typography>
            <form
                style={{ width: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                autoComplete="off"
                onSubmit={(e) => e.preventDefault()}
            >
                <TextField
                    label="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    variant="outlined"
                    color="secondary"
                    type="email"
                    sx={{ mb: 3, width: '100%' }}
                    value={email}
                    error={emailError}
                />
                <TextField
                    label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    variant="outlined"
                    color="secondary"
                    type="password"
                    value={password}
                    error={passwordError}
                    sx={{ mb: 3, width: '100%' }}
                />
                <Button
                    onClick={(e) => handleSubmit(e)}
                    variant="contained"
                    color="secondary" // Use secondary color from the theme
                    type="submit"
                    sx={{ width: '100%' }}
                >
                    Login
                </Button>
            </form>
        </Box>
    );
};
