import { useEventStore } from '../../store/eventStore';
import { Api, CountryInputDto, CountryOutputDto, EventInputDto, EventOutputDto } from '../autogenerated/Api';
import { Auth } from '../baseApiConfig';

const updateEvent = (eventId : string, data: EventInputDto): Promise<EventOutputDto> => {
    return new Promise((resolve, reject) => {
      const axiosConfig = {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getAccessToken()}`,
        },
      };
  
      const endpoint = new Api({ ...axiosConfig });
      endpoint.api
        .updateEventUsingPatch(eventId, data)
        .then((response) => {
          if (response.data) {
            useEventStore.getState().updateEvent(response.data);
            resolve(response.data); // Resolve the promise with response data
          } else {
            reject(new Error('Response data not available')); // Reject if response data is missing
          }
        })
        .catch((error) => {
          console.error('Error making API call:', error);
          if (error.response && error.response.status === 403) {
            Auth.removeAccessToken();
          }
          reject(error); // Reject the promise with the error
        });
    });
  };
export default updateEvent;