import { ReactElement, useEffect, useState } from 'react'
import getEvents from '../../services/network/EventMngtService/GetEvent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';

import { useEventStore } from '../../services/store/eventStore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import getRegions from '../../services/network/RegionsMngt/GetRegions';
import EventModal from '../../Components/Modal/EventModal';
import { EventDetails } from './EventDetails';
import { useNavigate } from 'react-router-dom';
import Fab from '@mui/material/Fab';


export const EventMngt: React.FC = (): ReactElement => {
    const { events } = useEventStore();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // State to manage the modal open/close
    const [selectedEventId, setSelectedEventId] = useState<string | null>(null); // State to keep track of selected event for editing
    
    useEffect(() => {
          getEvents()
          getRegions()
      }, []);

      const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleSeeDetails = (eventId?: string) =>{
        if(eventId){
            navigate(`/events/${eventId}`);
        }
    }
    const handleEdit = (eventId?: string) => {
      if(eventId){
        setSelectedEventId(eventId);
        setIsModalOpen(true);
      }
      
  };

  const handleDelete = (eventId?: string) => {
      // Implement your delete logic here
  };

  return (
      <>
          <TableContainer component={Paper}>
              <Table sx={{  minWidth:650 }} aria-label="simple table">
                  <TableHead>
                      <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell align="left">Region</TableCell>
                          <TableCell align="left">Announcement DateTime</TableCell>
                          <TableCell align="left">Event DateTime</TableCell>
                          <TableCell align="left">Participants/Capacity</TableCell>
                          <TableCell align="left">Reservation Price</TableCell>
                          <TableCell align="left">Actions</TableCell> {/* Add Actions column */}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {events.map((e) => (
                          <TableRow key={e.id}>
                              <TableCell component="th" scope="row">
                                  {e.id}
                              </TableCell>
                              <TableCell align="left">{e.region?.name +' / '+ e.region?.country?.name}</TableCell>
                              <TableCell align="left">{e.announcementDateTime}</TableCell>
                              <TableCell align="left">{e.dateTime}</TableCell>
                              <TableCell align="left">{e.numberOfParticipants}/{e.capacity}</TableCell>
                              <TableCell align="left">{e.reservationPrice}</TableCell>
                              <TableCell align="left">
                              <Tooltip title="Details">
                                      <IconButton onClick={() => handleSeeDetails(e.id)}>
                                          <VisibilityIcon />
                                      </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Edit">
                                      <IconButton onClick={() => handleEdit(e.id)}>
                                          <EditIcon />
                                      </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete">
                                      <IconButton onClick={() => handleDelete(e.id)}>
                                          <DeleteIcon />
                                      </IconButton>
                                  </Tooltip>
                              </TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
         <Fab
                color="primary"
                aria-label="add"
                onClick={openModal}
                sx={{
                    position: 'fixed',
                    bottom: '5vh',
                    right: '5vw',
                    zIndex: 9999, // Ensure it's above other elements
                }}
            >
                <AddIcon />
            </Fab>
            <EventModal isOpen={isModalOpen} onClose={closeModal} selectedEvent={events.find(e => e.id === selectedEventId)} />
    </>
  );
}
