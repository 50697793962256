import {
    AppBar,
    Avatar,
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Toolbar,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { ReactElement } from 'react'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink, Outlet } from 'react-router-dom';
import { Event, SportsEsports, Person, Business, InsertDriveFile } from '@mui/icons-material';
import logoGif from '../../meltpot-logo.gif';
import { Auth } from '../services/network/baseApiConfig'
import PublicIcon from '@mui/icons-material/Public';


export const Navigation: React.FC = (): ReactElement => {

 
    const navigate = useNavigate();

    //Todo fazer Links e underline onde estamos
    //Todo Fazer Main Theme Mui
    const theme = useTheme()
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);


    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };
   
    const useAuth = () => {
        if (!Auth.validateToken()  ) {
          navigate("/login")
        }
        return null;
      };

    return (
        <>
        {useAuth()}
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerOpen}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={logoGif} alt="Logo" style={{ marginRight: '8px', maxHeight: '40px' }} />
                            Meltpot BackOffice
                        </Box>
                    </Typography>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <MenuItem component={RouterLink} to="/events">
                            Events
                        </MenuItem>
                        <MenuItem component={RouterLink} to="/games">
                            Games
                        </MenuItem>
                        <MenuItem component={RouterLink} to="/users">
                            Users
                        </MenuItem>
                        <MenuItem component={RouterLink} to="/business">
                            Business
                        </MenuItem>
                        <MenuItem component={RouterLink} to="/countryRegion">
                            Country & Region
                        </MenuItem>
                        <MenuItem component={RouterLink} to="/media">
                            Media
                        </MenuItem>
                    </Box>
                        <IconButton color="inherit" sx={{ ml: 2 }}>
                            <Avatar alt="User" src="/" />
                        </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose}>
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={handleDrawerClose}
                    onKeyDown={handleDrawerClose}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: 2,
                            bgcolor: 'primary.main',
                        }}
                    >
                        <img src={logoGif} alt="Logo" style={{ marginBottom: '8px', maxHeight: '40px' }} />
                        <Typography variant="h6" color="white">
                            Meltpot BackOffice
                        </Typography>
                    </Box>
                    <List>
                        <ListItem button component={RouterLink} to="/events">
                            <ListItemIcon>
                                <Event />
                            </ListItemIcon>
                            <ListItemText primary="Events" />
                        </ListItem>
                        <ListItem button component={RouterLink} to="/games">
                            <ListItemIcon>
                                <SportsEsports />
                            </ListItemIcon>
                            <ListItemText primary="Games" />
                        </ListItem>
                        <ListItem button component={RouterLink} to="/users">
                            <ListItemIcon>
                                <Person />
                            </ListItemIcon>
                            <ListItemText primary="Users" />
                        </ListItem>
                        <ListItem button component={RouterLink} to="/business">
                            <ListItemIcon>
                                <Business />
                            </ListItemIcon>
                            <ListItemText primary="Business" />
                        </ListItem>
                        <ListItem button component={RouterLink} to="/countryRegion">
                            <ListItemIcon>
                                <PublicIcon />
                            </ListItemIcon>
                            <ListItemText primary="Country Region" />
                        </ListItem>
                        <ListItem button component={RouterLink} to="/media">
                            <ListItemIcon>
                                <InsertDriveFile />
                            </ListItemIcon>
                            <ListItemText primary="Media" />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <Box sx={{ flexGrow: 1 }}>
                <Outlet />
            </Box>
        </Box>
        </>
    )
}
