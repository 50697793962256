import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import postCountry from '../../services/network/CountryMngt/PostCountry';
import {  IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import postRegion from '../../services/network/RegionsMngt/PostRegion';
import { useBusinessStore } from '../../services/store/businessStore';
import { CountryInputDto } from '../../services/network/autogenerated/Api';

interface AddEditCountryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddCountryModal: React.FC<AddEditCountryModalProps>  = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState<CountryInputDto>({
    name: '',
    flagCode:''
  });


   const handlePostCountry = () => {
            const data = { name: formData.name, flagCode: formData.flagCode }; // Prepare data object
            postCountry(data)
                .then(() => {
                    onClose(); // Close modal upon successful post
                })
                .catch((error) => {
                    console.error('Error posting country:', error);
                
                });
    }

    const validateForm = () => {
        return (
          formData.name &&
          formData.name.length > 0 &&
          formData.flagCode &&
          /^[a-zA-Z]{2}$/.test(formData.flagCode)
        );
      };
      
  return (
    <>
    <Modal open={isOpen} onClose={onClose}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', display:'flex', flexDirection:'column' }}>
              <h2 style={{ display: 'flex', alignSelf:'center' }}>Create Country</h2>
              <TextField
                  sx={{ minWidth: 400 }}
                  label="Country Name"
                  value={formData.name}
                  onChange={(e) => setFormData({...formData, name : e.target.value})}
                  fullWidth
                  margin="normal"
              />
              <TextField
                  label="Country Flag Code"
                  value={formData.flagCode}
                  onChange={(e) => setFormData({...formData, flagCode : e.target.value})}
                  fullWidth
                  margin="normal"
              /> 
              <div style={{ alignSelf: 'center', marginTop:'1.5rem'}}>
                  <Button onClick={handlePostCountry} variant="contained" color="primary"  disabled={!validateForm()}>Create Country</Button>
              </div>
          </div>
    </Modal>
</>

  );
};

export default AddCountryModal;
