import { ReactElement, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useMediaStore } from '../../services/store/mediaStore';
import retrieveFiles from '../../services/network/MediaMngt/RetrieveFiles';
import uploadFile from '../../services/network/MediaMngt/UploadFile';


export const MediaMngt: React.FC = (): ReactElement => {
    const [mediaFile, setMediaFile] = useState<File | null>(null); // State to store uploaded media file
    const {mediaFiles} = useMediaStore();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => { retrieveFiles()}
    ,[])

    
    // Function to handle file upload
    const handleFileUpload = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setMediaFile(file)
        }
    };

    // Function to handle media upload
    const handleUpload = () => {
        if(mediaFile){
            uploadFile(mediaFile)
        }

    };

    return (
        <>
         <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>URL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mediaFiles.map((row: string, index:number) => (
            <TableRow key={index}>
              <TableCell>{row}</TableCell>
              <TableCell>
                <a href={apiUrl + '/api/v1/storage/download/'+ row} target="_blank" rel="noopener noreferrer">
                  {apiUrl + '/api/v1/storage/download/'+ row}
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Box p={3}>
            <Grid container spacing={2}>  
            </Grid>
            <Box mt={3}>
                <Typography variant="h4" gutterBottom>
                    Upload Media
                </Typography>
                <TextField
                    type="file"
                    onChange={handleFileUpload}
                    variant="outlined"
                   // accept="image/*, video/*"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpload}
                    disabled={!mediaFile}
                >
                    Upload
                </Button>
            </Box>
        </Box>
        </>
       
    );
};

