import { publicDecrypt } from "crypto";
import { Api } from "./autogenerated/Api";

//No set e no get deviamos aplicar criptografia para minimizar cross site.
export class Auth {

  static getAccessToken() {
    try {
      // Retrieve the encrypted token from localStorage
      const accessToken = localStorage.getItem('accessToken');
      return accessToken;
    } catch (error) {
      console.error('Error retrieving access token:', error);
      return null;
    }
  }

  // Store a securely generated access token in localStorage
  //Falta guardar role de forma encryptada. Colocar chave no .env?
     static storeAccessToken(accessToken: string) {
     
      try {
          localStorage.setItem('accessToken', accessToken);
      } catch (error) {
          console.error('Error storing access token:', error);
      }
  }

  static removeAccessToken(){
    try{
      
      localStorage.removeItem('accessToken')
    
    }catch (error) {
      console.error('Error removing access token:', error);
  }
  }
  static validateToken(){
    const token = localStorage.getItem('accessToken');

    return token !== undefined && token !== null && token.length > 0
  }
     

}

  